define("discourse/plugins/discourse-bbcode/lib/discourse-markdown/bbcode", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;

  function replaceFontColor(text) {
    while (text !== (text = text.replace(/\[color=([^\]]+)\]((?:(?!\[color=[^\]]+\]|\[\/color\])[\S\s])*)\[\/color\]/gi, function (match, p1, p2) {
      return "<font color='".concat(p1, "'>").concat(p2, "</font>");
    }))) {}

    return text;
  }

  function replaceFontSize(text) {
    while (text !== (text = text.replace(/\[size=([^\]]+)\]((?:(?!\[size=[^\]]+\]|\[\/size\])[\S\s])*)\[\/size\]/gi, function (match, p1, p2) {
      return "<font size='".concat(p1, "'>").concat(p2, "</font>");
    }))) {}

    return text;
  }

  function replaceFontFace(text) {
    while (text !== (text = text.replace(/\[font=([^\]]+)\]((?:(?!\[font=[^\]]+\]|\[\/font\])[\S\s])*)\[\/font\]/gi, function (match, p1, p2) {
      return "<font face='".concat(p1, "'>").concat(p2, "</font>");
    }))) {}

    return text;
  }

  function wrap(tag, attr, callback) {
    return function (startToken, finishToken, tagInfo) {
      startToken.tag = finishToken.tag = tag;
      startToken.content = finishToken.content = "";
      startToken.type = "bbcode_open";
      finishToken.type = "bbcode_close";
      startToken.nesting = 1;
      finishToken.nesting = -1;
      startToken.attrs = [[attr, callback ? callback(tagInfo) : tagInfo.attrs._default]];
    };
  }

  function setupMarkdownIt(md) {
    var ruler = md.inline.bbcode.ruler;
    ruler.push("size", {
      tag: "size",
      wrap: wrap("font", "size")
    });
    ruler.push("font", {
      tag: "font",
      wrap: wrap("font", "face")
    });
    ruler.push("color", {
      tag: "color",
      wrap: wrap("font", "color")
    });
    ruler.push("bgcolor", {
      tag: "bgcolor",
      wrap: wrap("span", "style", function (tagInfo) {
        return "background-color:" + tagInfo.attrs._default.trim();
      })
    });
    ruler.push("highlight", {
      tag: "highlight",
      wrap: "span.highlight"
    });
    ruler.push("small", {
      tag: "small",
      wrap: wrap("span", "style", function () {
        return "font-size:x-small";
      })
    });
    ruler.push("aname", {
      tag: "aname",
      wrap: wrap("a", "name")
    });
    ruler.push("jumpto", {
      tag: "jumpto",
      wrap: wrap("a", "href", function (tagInfo) {
        return "#" + tagInfo.attrs._default;
      })
    });
    ["left", "right", "center"].forEach(function (dir) {
      md.block.bbcode.ruler.push(dir, {
        tag: dir,
        wrap: function wrap(token) {
          token.attrs = [["style", "text-align:" + dir]];
          return true;
        }
      });
    });
    md.block.bbcode.ruler.push("indent", {
      tag: "indent",
      wrap: "blockquote.indent"
    });
    ["ot", "edit"].forEach(function (tag) {
      md.block.bbcode.ruler.push("ot", {
        tag: tag,
        before: function before(state) {
          var token = state.push("sepquote_open", "div", 1);
          token.attrs = [["class", "sepquote"]];
          token = state.push("span_open", "span", 1);
          token.block = false;
          token.attrs = [["class", "smallfont"]];
          token = state.push("text", "", 0);
          token.content = _I18n.default.t("bbcode." + tag);
          token = state.push("span_close", "span", -1);
          state.push("soft_break", "br", 0);
          state.push("soft_break", "br", 0);
        },
        after: function after(state) {
          state.push("sepquote_close", "div", -1);
        }
      });
    });
    ["list", "ul", "ol"].forEach(function (tag) {
      md.block.bbcode.ruler.push(tag, {
        tag: tag,
        replace: function replace(state, tagInfo, content) {
          var ol = tag === "ol" || tag === "list" && tagInfo.attrs._default;
          var token;

          if (ol) {
            token = state.push("ordered_list_open", "ol", 1);

            if (tagInfo.attrs._default) {
              token.attrs = [["type", tagInfo.attrs._default]];
            }
          } else {
            state.push("bullet_list_open", "ul", 1);
          }

          var lines = content.split("\n");
          var list = [null];
          var index = 0;

          for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            var match = line.match(/^\s*\[?\*\]?(.*)/);

            if (match) {
              index++;
              list[index] = match[1];
              continue;
            }

            match = line.match(/\s*\[li\](.*)\[\/li\]\s*$/);

            if (match) {
              index++;
              list[index] = match[1];
              continue;
            }

            if (list[index]) {
              list[index] += "\n" + line;
            } else {
              list[index] = line;
            }
          }

          list.forEach(function (li) {
            if (li !== null) {
              state.push("list_item_open", "li", 1); // a bit lazy, we could use a block parser here
              // but it means a lot of fussing with line marks

              token = state.push("inline", "", 0);
              token.content = li;
              token.children = [];
              state.push("list_item_close", "li", -1);
            }
          });

          if (ol) {
            state.push("ordered_list_close", "ol", -1);
          } else {
            state.push("bullet_list_close", "ul", -1);
          }

          return true;
        }
      });
    });
  }

  function setup(helper) {
    helper.allowList(["div.highlight", "span.highlight", "div.sepquote", "span.smallfont", "blockquote.indent", "font[color=*]", "font[size=*]", "font[face=*]", "ol[type=*]"]);
    helper.allowList({
      custom: function custom(tag, name, value) {
        if (tag === "span" && name === "style") {
          return /^(font-size:(xx-small|x-small|small|medium|large|x-large|xx-large)|background-color:#?[a-zA-Z0-9]+)$/.exec(value);
        }

        if (tag === "div" && name === "style") {
          return /^text-align:(center|left|right)$/.exec(value);
        }
      }
    });
    helper.registerOptions(function (opts) {
      opts.features["bbcode"] = true;
    });

    if (helper.markdownIt) {
      helper.registerPlugin(setupMarkdownIt);
      return;
    }

    var builders = requirejs("pretty-text/engines/discourse-markdown/bbcode").builders;

    var _builders = builders(helper),
        register = _builders.register,
        replaceBBCode = _builders.replaceBBCode,
        rawBBCode = _builders.rawBBCode,
        replaceBBCodeParamsRaw = _builders.replaceBBCodeParamsRaw;

    replaceBBCode("small", function (contents) {
      return ["span", {
        style: "font-size:x-small"
      }].concat(contents);
    });
    replaceBBCode("highlight", function (contents) {
      return ["div", {
        class: "highlight"
      }].concat(contents);
    });
    ["left", "center", "right"].forEach(function (direction) {
      replaceBBCode(direction, function (contents) {
        return ["div", {
          style: "text-align:" + direction
        }].concat(contents);
      });
    });
    replaceBBCode("edit", function (contents) {
      return ["div", {
        class: "sepquote"
      }, ["span", {
        class: "smallfont"
      }, "Edit:"], ["br"], ["br"]].concat(contents);
    });
    replaceBBCode("ot", function (contents) {
      return ["div", {
        class: "sepquote"
      }, ["span", {
        class: "smallfont"
      }, "Off Topic:"], ["br"], ["br"]].concat(contents);
    });
    replaceBBCode("indent", function (contents) {
      return ["blockquote", ["div"].concat(contents)];
    });
    helper.addPreProcessor(replaceFontColor);
    helper.addPreProcessor(replaceFontSize);
    helper.addPreProcessor(replaceFontFace);
    register("aname", function (contents, param) {
      return ["a", {
        name: param,
        "data-bbcode": true
      }].concat(contents);
    });
    register("jumpto", function (contents, param) {
      return ["a", {
        href: "#" + param,
        "data-bbcode": true
      }].concat(contents);
    });
    register("rule", function (contents, param) {
      return ["div", {
        style: "margin: 6px 0; height: 0; border-top: 1px solid " + contents + "; margin: auto; width: " + param
      }];
    });
    rawBBCode("noparse", function (contents) {
      return contents;
    });
    rawBBCode("fphp", function (contents) {
      return ["a", {
        href: "http://www.php.net/manual-lookup.php?function=" + contents,
        "data-bbcode": true
      }, contents];
    });
    replaceBBCodeParamsRaw("fphp", function (param, contents) {
      return ["a", {
        href: "http://www.php.net/manual-lookup.php?function=" + param,
        "data-bbcode": true
      }, contents];
    });
    rawBBCode("google", function (contents) {
      return ["a", {
        href: "http://www.google.com/search?q=" + contents,
        "data-bbcode": true
      }, contents];
    });
    helper.replaceBlock({
      start: /\[list=?(\w)?\]([\s\S]*)/gim,
      stop: /\[\/list\]/gim,
      emitter: function emitter(blockContents, matches) {
        var _this = this;

        var contents = matches[1] ? ["ol", {
          type: matches[1]
        }] : ["ul"];

        if (blockContents.length) {
          blockContents.forEach(function (bc) {
            var lines = bc.split(/\n/);
            lines.forEach(function (line) {
              if (line.indexOf("[*]") === 0) {
                var li = _this.processInline(line.slice(3));

                if (li) {
                  contents.push(["li"].concat(li));
                }
              }
            });
          });
        }

        return contents;
      }
    });
  }
});

